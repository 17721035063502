.landingPageContainer{
    height:100%;
    width: 100%;
    
    padding-bottom: 20px;
    //padding:1rem;
    //margin-left: -10px;
    //margin-right: -10px;
    margin-bottom: 10px;
    
}
.landingPageCover, .coyoteCssClassCover{
    background-color: white;
    max-width: 40em;
    position:relative; 
    border-radius: 0.5em;
    box-shadow: 0 1px 1px rgb(156 156 156);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content:space-around;
    padding: 20px;
    margin-top: 70px;
    .imgLogoSurepoint{
        width: 35%;
        padding: 0.5rem 1rem 1rem 1rem;
        
     }
     .lmsTitle{
        font-size: 25px;
        font-weight: 
        300 ;
        line-height: 10.00px;
        padding: 1rem 1rem 1rem 1rem;
     }
    .landingColorText22{
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
    }
}
.coyoteCssClassCover{
 
 max-width: 40em;
 border-radius: 10px;
 box-shadow: 0;
 .imgLogoSurepoint{
    width: 35%;
    padding: 0.5rem 1rem 1rem 1rem;
 }

 .coyoteTitle{
    font-size: 36px;
    font-weight: 
    300 ;
    line-height: 42.19px;
    padding: 1rem 1rem 3rem 1rem;
 }
}
.coyoteContainer{
    div:nth-child(1){
        flex-basis: 45%;
        max-width: 100%;
    }
    div:nth-child(2){
        flex-basis: 45%;
        max-width: 100%;
    }
    div:nth-child(3){
        flex-basis: 45%;
        max-width: 100%;
    }
    div:nth-child(4){
        flex-basis: 45%;
        max-width: 100%;
    }
    .imgIconCenter{
        width:117px;
        height: 120px;
    }
    .landingColorText22{
        font-size: 20px;
        font-weight: 500;
    }
    .dvGroup{
        width: 100%;
        height: 100%;
    }
}
.bglandingPageTopRight {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(229, 246, 255);
    padding-top:20px;
}
.landingColorText{
    //color:'#344054';
    color: #000000;
    font-weight: 500;
    font-size:14px;
    font-family: 'Roboto';
    vertical-align: middle ;
    line-height: 20px;

}
.landingColorText2{
    //color:'#344054';
    color: #000000;
    font-weight: 400;
    font-size:16px;
    font-family: 'Roboto';
    align-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    //padding-left: 10px;
}
.landingColorTextCoyote{
    //color:'#344054';
    color: #000000;
    font-weight: 400;
    font-size:16px;
    font-family: 'Roboto';
    align-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    //padding-left: 10px;
}
.landingColorText22, .landingColorSubText{
    //color:'#344054';
    color: #000000;
    font-weight: 400;
    font-size:16px;
    font-family: 'Roboto';
    align-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    text-transform: capitalize;
}
.landingColorSubText{
    font-size: 18px;
    font-weight: 400;
}
.landingColorText3{
    //color:'#344054';
    color: #000000;
    font-weight: 400;
    font-size:15px;
    font-family: 'Roboto';
    vertical-align: middle ;
    line-height: 26px;
    text-transform: capitalize;
}
.dvGroup {
    align-content: center;
    align-items:  center;
    align-self: center;
    text-align: center;
    height: 135px;
    width: 100px;
    margin: 0 auto;
    
}
.butHeader{
    background-color: #1565C0;
    width: 40px;
    height: 40px;
}
.dvContainer{ 
    width:100%;
    height:100%;
}
.imgDropDownRight{
    width:12px; 
    height:8px;
}
.imgLogoSurepoint{
    width:50%;
    padding: 2rem 1rem 2rem 1rem;
}

.imgIconCenter {
    width:74px;//67px;
    height: 84px;//83px;
    //padding-top: 30px;
    //margin-top: 20px;
    //padding:0rem 1rem 1rem 1rem;
    //text-align: center;
    //vertical-align: middle;
    //padding-left: 5px;
    //padding-bottom: 10px;
    //padding-right: 38px;
    
    padding-bottom: 10px;
    // align-content: center;
    // align-items:  center;
    // align-self: center;
    text-align: center;
}
.heightSpace {
    height:29px;
}
.rememberMe{
    text-align:center;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
    margin: 0 auto;
}
.paddingSpaceBottom{
    height:10px
}
.userManagementdv{
    align-self: center;
    align-items: center;
    align-self: center;
    text-align: center;
}
.toolManegement {
    width:21px; 
    height:20px;
}

.topMenuHeader{
    height: 71px;
}
